<template>
  <div class="base-info__wrapper">
    <div class="base-info__title">{{ $t('account_manager.basic_info')}}</div>
    <div class="base-info__content">
      <div class="base-info__logo">
        <div class="logo-wrap">
          <img :src="accountLogo" alt="">
          <input ref="fileInput" style="display: none;" type="file" :multiple="false" @change="getImg" accept="image/png,image/gif,image/jpeg" name="file" />
          <div class="action-wrap" @click="handleUpdate">
          </div>
          <svg-icon class="changeAvatar" name="xiangji" @click="handleUpdate"></svg-icon>
        </div>
      </div>
      <div class="base-info__menu">
        <div class="base-info__item">
          <div class="base-item__label">{{ $tLabel($t('label.username')) }}</div>
          <div class="base-item__value">
            <el-tooltip
              :effect="$isDark() ? 'dark' : 'light'"
              :content="currentUserInfo.username"
              popper-class="plan-info-tips"
              :offset="0"
              placement="top"
            >
              <span>{{ currentUserInfo.username }}</span>
            </el-tooltip>
            <copy-icon
              style="vertical-align: middle;width:20px;"
              :left="16"
              name="username"
              :copyText="currentUserInfo.username"
            />
            <template v-if="currentUserInfo.account_source !== '360 corp'">
              <span class="line"></span>
              <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('action.edit')" placement="top">
                <svg-icon name="bianji11" @click="modifyUserNameDialogVisible = true"></svg-icon>
              </el-tooltip>
            </template>
          </div>
        </div>
        <div class="base-info__item">
          <div class="base-item__label">{{ $tLabel($t('account_manager.regist_time')) }}</div>
          <div class="base-item__value">{{ currentUserInfo.date_joined || $t('tip.noYet') }}</div>
        </div>
        <div class="base-info__item">
          <div class="base-item__label">{{ $tLabel($t('label.email')) }}</div>
          <div class="base-item__value">
            <span>{{ currentUserInfo.email?.replace(reg,va=> currentUserInfo.email[0] + '*'.repeat(va.length - 1)) }}</span>
            <template v-if="currentUserInfo.email">
              <copy-icon
                style="vertical-align: middle;width:20px;"
                :right="0"
                :left="16"
                name="email"
                :copyText="currentUserInfo.email"
              />
              <template v-if="currentUserInfo.account_source !== '360 corp'">
                <span class="line"></span>
                <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('action.edit')" placement="top">
                  <svg-icon name="bianji11" @click="edit('email')"></svg-icon>
                </el-tooltip>
              </template>
            </template>
            <span class="bindMobilePhone" @click="modifyEmailDialogVisible = true" v-else>{{ $t('account_manager.bind_email') }}</span>
          </div>
        </div>
        <div class="base-info__item">
          <div class="base-item__label">{{ $tLabel($t('label.phone')) }}</div>
          <div class="base-item__value">
            <span>{{ currentUserInfo.phone?.replace(reg1, '$1****$3') }}</span>
            <template v-if="currentUserInfo.phone">
              <copy-icon
                style="vertical-align: middle;width:20px;"
                :right="0"
                :left="16"
                name="phone"
                :copyText="currentUserInfo.phone"
              />
              <span class="line"></span>
              <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('action.edit')" placement="top">
                <svg-icon name="bianji11" style="height: 40px;" @click="edit('phone')"></svg-icon>
              </el-tooltip>
            </template>
            <span class="bindMobilePhone" v-else @click="modifyPhoneDialogVisible = true">{{ $t('account_manager.bind_phone') }}</span>
          </div>
        </div>
        <div class="base-info__item base-info__item--line">
          <div class="base-item__label">{{ $tLabel($t('label.company')) }}</div>
          <div class="base-item__value">
            <span>{{ currentUserInfo.company && currentUserInfo.company.replace(new RegExp(' ', 'g'), '').replace(new RegExp('\n', 'g'), '') ? currentUserInfo.company.replace(new RegExp(' ', 'g'), '').replace(new RegExp('\n', 'g'), '') : '-' }}</span>
            <template v-if="currentUserInfo.company && currentUserInfo.company.replace(new RegExp(' ', 'g'), '').replace(new RegExp('\n', 'g'), '')">
              <copy-icon
                style="vertical-align: middle;width:20px;"
                :right="0"
                :left="16"
                name="company"
                :copyText="currentUserInfo.company.replace(new RegExp(' ', 'g'), '').replace(new RegExp('\n', 'g'), '')"
              />
            </template>
          </div>
        </div>
        <div class="base-info__item api-key__item">
          <div class="base-item__label">
            API KEY：
            <!-- <el-tooltip
              popper-class="plan-info-tips"
              :effect="$isDark() ? 'dark' : 'light'"
              content="API 服务已经升级，V1版本 API 接口将持续提供服务到 2023 年 2 月 28 日 15 点，之后将不再提供服务。为了保证您业务使用正常，请您尽快按照“帮助中心”说明文档，使用新的 API KEY 和 V2 版本 API 接口请求方式进行 API 服务调用。"
              placement="top-start"
            >
              <span class="tishi-wrap">
                <svg-icon name="zhushi"></svg-icon>
              </span>
            </el-tooltip> -->
          </div>
          <div class="base-item__value">
            <!-- <el-tooltip
              :content="currentUserInfo.api_token"
              popper-class="plan-info-tips"
              :offset="0"
              v-if="currentUserInfo.api_token"
              placement="top"
            > -->
            <span class="api-key__wrapper">{{ currentUserInfo.api_token}}</span>
            <!-- </el-tooltip> -->
            <template v-if="currentUserInfo.api_token">
              <copy-icon
                style="vertical-align: middle;width:20px;"
                :right="0"
                :left="14"
                name="name"
                :copyText="currentUserInfo.api_token"
              />
              <span class="line"></span>
            </template>
            <span class="api-key__action reset-api__key" @click="handleReset">{{ currentUserInfo.api_token ? $t('account_manager.reset_apikey') : $t('account_manager.create_apikey') }}</span>
            <span class="line"></span>
            <span class="api-key__action" @click="goApiInfo">{{ $t('account_manager.apikey_info') }}</span>
          </div>
        </div>
        <!-- <div class="base-info__item api-key__info">
          {{ $t('account_manager.apikey_desc') }}
        </div> -->
        <!-- <div class="base-info__item api-key__info">
          API 服务已经升级，V1版本 API 接口将持续提供服务到 2023 年 2 月 28 日 15 点，之后将不再提供服务。为了保证您业务使用正常，请您尽快按照“帮助中心”说明文档，使用新的 API KEY 和 V2 版本 API 接口请求方式进行 API 服务调用。
        </div> -->
      </div>
    </div>
    <!-- <update-user-dialog :updateVisible="visible" :defaultData="currentUserInfo" @close="closeDialog" @update="updateInfo" /> -->
    <cropper-dialog :updateVisible="imgVisible" :defaultImg="defaultImg" @close="closeImgDialog" @updateImg="updateImg"></cropper-dialog>
    <el-dialog
      v-model="modifyUserNameDialogVisible"
      :title="$t('account_manager.edit_user.action')"
      width="30%"
      :before-close="closeUpdateUserNameDialog"
    >
      <div class="deleteContent">
        <el-form
          label-width="60px"
          :model="model"
          :rules="rules"
          style="width: 100%"
          ref="formRef"
        >
          <el-form-item :label="$tLabel($t('label.username'))" prop="username">
            <el-input v-model="model.username" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateUser(formRef)">{{ $t('action.confirm') }}</el-button>
          <el-button @click="closeUpdateUserNameDialog">{{ $t('action.cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <verification
      v-if="verificationDialogVisible"
      v-model:show="verificationDialogVisible"
      :type="editType"
      :email="currentUserInfo.email"
      :phone="currentUserInfo.phone"
      @verifySuccess = verifySuccess
    />
    <el-dialog
      v-if="modifyEmailDialogVisible"
      v-model="modifyEmailDialogVisible"
      :title="currentUserInfo.email ? $t('account_manager.bind_new_email') : $t('account_manager.bind_email')"
      :width="$isZh() ? '440px' : '494px'"
    >
      <div class="modifyEmailDialog">
        <el-form
          :label-width="$isZh() ? '72px' : '136px'"
          :model="model"
          :rules="rules"
          style="width: 100%"
          ref="emailRef"
        >
          <!-- <el-form-item label="邮箱地址：" prop="email">
            <el-input v-model="model.email" />
          </el-form-item> -->
          <el-form-item :label="$tLabel($t('label.email'))" prop="email">
            <el-autocomplete
              class="email"
              v-model.trim="model.email"
              :fetch-suggestions="querySearchEmail"
              :input-style="{ width: '300px' }"
              :placeholder="$tInput($t('label.email'))"
              popper-class="advanced-operate"
            />
          <!-- <el-input v-model="formData.email" placeholder="请输入联系邮箱"></el-input> -->
        </el-form-item>
          <el-form-item :label="$tLabel($t('account_manager.verification_code'))" prop="email_code">
            <div class="code-item">
              <el-input type="text" maxlength="6" :placeholder="$tInput()" v-model="model.email_code"/>
              <verificationCodeBtn ref="verificationRef" @send="sendEmailCode" :loading="loading"/>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateEmail">{{ $t('action.confirm') }}</el-button>
          <el-button @click="modifyEmailDialogVisible = false">{{ $t('action.cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="modifyPhoneDialogVisible"
      :title="currentUserInfo.phone ? $t('account_manager.bind_new_phone') : $t('account_manager.bind_phone')"
      :width="$isZh() ? '440px' : '486px'"
      @close="model.phone = ''"
    >
      <div class="modifyEmailDialog">
        <el-form
          :label-width="$isZh() ? '80px' : '136px'"
          :model="model"
          :rules="rules"
          style="width: 100%"
          ref="phoneRef"
        >
          <el-form-item :label="$tLabel($t('account_manager.country'))" prop="country">
            <el-select style="width: 100%;" disabled v-model="selectCountry">
              <el-option :label="$t('account_manager.chinese_mainland', ['+86'])" value="+86" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$tLabel($t('label.phone'))" prop="phone">
            <el-input v-model.trim="model.phone" :placeholder="$tInput($t('label.phone'))" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))" maxlength="11" />
          </el-form-item>
          <el-form-item :label="$tLabel($t('account_manager.verification_code'))" prop="phone_code">
            <div class="code-item">
              <el-input type="text" maxlength="6" :placeholder="$tInput()" v-model="model.phone_code"/>
              <verificationCodeBtn ref="verificationRef" @send="sendPhoneCode" :loading="loading"/>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updatePhone">{{ $t('action.confirm') }}</el-button>
          <el-button @click="modifyPhoneDialogVisible = false">{{ $t('action.cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="resetDialogVisible"
      :title="$t('label.attention')"
      width="30%"
    >
      <div class="deleteContent">
        <svg-icon name="info"></svg-icon>
        <span style="line-height: 21px;">{{ $t('account_manager.reset_apikey_info') }}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetDialogVisible = false">{{ $t('action.cancel') }}</el-button>
          <el-button type="primary" @click="handleResetApikey">{{ $t('action.confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, computed, getCurrentInstance } from 'vue'
// import dayjs from 'dayjs'
// import { useState } from '@/hooks/useVuex'
import verificationCodeBtn from './verification-code-btn.vue'
import verification from './verification'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import CropperDialog from './cropper-dialog.vue'
import { getAccountInfo, editUserAvatar, resetApiKey, sendModifyEmailCode, modifyEmail, modifyUserName, sendSMSCode, modifyPhone } from '@/services/account'
// import updateUserDialog from './update-user-dialog.vue'
import { message } from '@/hooks/useUI'
import { isUserName, isEmail, isPhone } from '@/utils/validate'
import { t, isZh } from 'app/i18n'

// import { useState } from '@/hooks/useVuex'
// const { userInfo } = useState('user', ['userInfo'])
const selectCountry = ref(isZh() ? '中国大陆 +86' : 'Chinese +86')
const gProperties = getCurrentInstance().appContext.config.globalProperties
const visible = ref(false)
const resetDialogVisible = ref(false)
const modifyUserNameDialogVisible = ref(false)
const modifyEmailDialogVisible = ref(false)
const verificationDialogVisible = ref(false)
const modifyPhoneDialogVisible = ref(false)
const fileInput = ref()
const defaultImg = ref()
const imgVisible = ref(false)
const router = useRouter()
const route = useRoute()
const store = useStore()
const currentUserInfo = ref({})
const model = ref({})
const formRef = ref()
const emailRef = ref()
const phoneRef = ref()
const editType = ref('')
const reg = ref(/^(.{1})(.+)(?=.{1}@)/g)
const verificationRef = ref()
const loading = ref(false)
const reg1 = ref(/^(\d{3})(\d{4})(\d{4})$/g)

const validateName = (rule, value, callback) => {
  if (value) {
    if (isUserName(value)) {
      callback()
    } else {
      callback(new Error(t('account_manager.edit_user.username_valid')))
    }
  } else {
    callback(new Error(t('account_manager.edit_user.username_empty')))
  }
}

const validateEmail = (rule, value, callback) => {
  if (value) {
    if (isEmail(value)) {
      if (value.length > 30) {
        callback(new Error(t('account_manager.edit_user.email_length')))
      } else {
        callback()
      }
    } else {
      callback(new Error(t('account_manager.edit_user.email_valid')))
    }
  } else {
    callback(new Error(t('account_manager.edit_user.email_empty')))
  }
}

const validateCode = (rule, value, callback) => {
  if (value) {
    callback()
    if (value.length < 6) {
      callback(new Error(t('account_manager.edit_user.verify_code_length')))
    }
  } else {
    callback(new Error(t('account_manager.edit_user.verify_code')))
  }
}

const validatePhone = (rule, value, callback) => {
  if (value) {
    if (isPhone(value)) {
      callback()
    } else {
      callback(new Error(t('account_manager.edit_user.phone_valid')))
    }
  } else {
    callback(new Error(t('account_manager.edit_user.phone_empty')))
  }
}

const querySearchEmail = (queryString, cb) => {
  if (queryString.indexOf('@') > 0 || !queryString) {
    return cb([])
  }
  const results = [
    {
      value: queryString + '@163.com'
    },
    {
      value: queryString + '@sina.com'
    },
    {
      value: queryString + '@qq.com'
    },
    {
      value: queryString + '@126.com'
    },
    {
      value: queryString + '@vip.sina.com'
    },
    {
      value: queryString + '@hotmail.com'
    },
    {
      value: queryString + '@gmail.com'
    },
    {
      value: queryString + '@sohu.com'
    },
    {
      value: queryString + '@yahoo.com'
    }
  ]
  cb(results)
}

const rules = ref({
  username: [
    { message: '请输入用户名', trigger: 'change' },
    { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
    { validator: validateName, trigger: 'blur' }
  ],
  email: [
    { message: '请输入邮箱', trigger: 'change' },
    { validator: validateEmail, trigger: 'blur' }
  ],
  email_code: [
    { message: '请输入验证码', trigger: 'change' },
    { validator: validateCode, trigger: 'blur' }
  ],
  phone: [
    { message: '请输入手机号', trigger: 'change' },
    { validator: validatePhone, trigger: 'blur' }
  ],
  phone_code: [
    { message: '请输入验证码', trigger: 'change' },
    { validator: validateCode, trigger: 'blur' }
  ]
})

const updateUser = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      if (model.value.username !== currentUserInfo.value.username) {
        modifyUserName({ username: model.value.username }).then(res => {
          if (res.code !== undefined && res.code !== 0) {
            message(res.message, 'error')
          } else {
            currentUserInfo.value.username = model.value.username
            message(t('account_manager.edit_user.username_modified'), 'success')
            modifyUserNameDialogVisible.value = false
          }
        })
      } else {
        modifyUserNameDialogVisible.value = false
      }
    } else {
      return false
    }
  })
}

const edit = (next) => {
  editType.value = next
  verificationDialogVisible.value = true
  // modifyEmailDialogVisible.value = true
}

// const isCorp = computed(() => {
//   return userInfo.value.account_source === '360 corp'
// })

const accountLogo = computed(() => {
  if (store.getters.userInfo.user_pic && store.getters.userInfo.user_pic.type && store.getters.userInfo.user_pic.base64) {
    return `data:image/${store.getters.userInfo.user_pic.type};base64,${store.getters.userInfo.user_pic.base64}`
  }
  return gProperties.$isDark() ? require('@/assets/img/avatar.png') : require('@/assets/img/avatar-light.png')
})

onBeforeMount(() => {
  getBaseData()
})

const sendEmailCode = () => {
  if (!emailRef.value) return
  emailRef.value.validateField('email', (valid, err) => {
    if (valid) {
      loading.value = true
      sendModifyEmailCode({ email: model.value.email }).then(res => {
        if (res.code !== undefined && res.code !== 0) {
          if (res.code === 1001) {
            message(t('account_manager.edit_user.code_send_err'), 'error')
          } else if (res.code === 3000) {
            message(t('account_manager.edit_user.email_modify_total_limited'), 'error')
          } else if (res.code === 3001) {
            message(t('account_manager.edit_user.email_modify_frequency_limited'), 'error')
          } else if (res.code === 5000) {
            message(t('account_manager.edit_user.email_binded_by_other'), 'error')
          } else {
            message(res.message, 'error')
          }
        } else {
          message(t('account_manager.edit_user.code_sended'), 'success')
          verificationRef.value.tackBtn()
        }
      }).finally(() => { loading.value = false })
    } else {
      // message(, 'error')
      console.log(valid)
    }
  })
}

const sendPhoneCode = () => {
  if (!phoneRef.value) return
  phoneRef.value.validateField('phone', (valid, err) => {
    if (valid) {
      loading.value = true
      sendSMSCode({ phone: model.value.phone }).then(res => {
        if (res.code !== undefined && res.code !== 0) {
          if (res.code === 1001) {
            message(t('account_manager.edit_user.code_send_err'), 'error')
          } else if (res.code === 3010) {
            message(t('account_manager.edit_user.phone_modify_total_limited'), 'error')
          } else if (res.code === 3011) {
            message(t('account_manager.edit_user.phone_modify_frequency_limited'), 'error')
          } else if (res.code === 5000) {
            message(t('account_manager.edit_user.phone_binded_by_other'), 'error')
          } else {
            message(res.message, 'error')
          }
        } else {
          message(t('account_manager.edit_user.code_sended'), 'success')
          verificationRef.value.tackBtn()
        }
      }).finally(() => { loading.value = false })
    } else {
      // message(, 'error')
      console.log(valid)
    }
  })
}

const updateEmail = () => {
  if (!emailRef.value) return
  emailRef.value.validate((valid) => {
    if (valid) {
      modifyEmail({
        code: model.value.email_code,
        email: model.value.email
      }).then(res => {
        if (res.code !== undefined && res.code !== 0) {
          if (res.code === 1003) {
            message(t('account_manager.edit_user.err_code'), 'error')
          } else if (res.code === 1002) {
            message(t('account_manager.edit_user.safety_verification_err'), 'error')
          } else {
            message(res.message, 'error')
          }
        } else {
          message(t('account_manager.edit_user.email_modified'), 'success')
          // currentUserInfo.value.email = model.value.email
          getBaseData()
          modifyEmailDialogVisible.value = false
        }
      })
    } else {
      return false
    }
  })
}

const closeUpdateUserNameDialog = () => {
  modifyUserNameDialogVisible.value = false
  model.value.username = currentUserInfo.value.username
}
const updatePhone = () => {
  if (!phoneRef.value) return
  phoneRef.value.validate((valid) => {
    if (valid) {
      modifyPhone({
        code: model.value.phone_code,
        phone: model.value.phone
      }).then(res => {
        if (res.code !== undefined && res.code !== 0) {
          if (res.code === 1003) {
            message(t('account_manager.edit_user.err_code'), 'error')
          } else if (res.code === 1002) {
            message(t('account_manager.edit_user.safety_verification_err'), 'error')
          } else {
            message(res.message, 'error')
          }
        } else {
          message(t('account_manager.edit_user.phone_modified'), 'success')
          // currentUserInfo.value.phone = model.value.phone
          getBaseData()
          modifyPhoneDialogVisible.value = false
        }
      })
    } else {
      return false
    }
  })
}

const getBaseData = () => {
  getAccountInfo().then((data) => {
    console.log(data)
    currentUserInfo.value = data.user

    model.value = {
      username: currentUserInfo.value.username || '',
      email: '',
      email_code: '',
      phone: '',
      phone_code: ''
    }
    store.commit('user/setUserInfo', data.user)
  })
}
const goApiInfo = () => {
  router.push({
    name: '/help/docs/api/introduction'
  })
}

const verifySuccess = () => {
  console.log(editType.value)
  if (editType.value === 'email') {
    modifyEmailDialogVisible.value = true
  }
  if (editType.value === 'phone') {
    // 手机号码验证陈工
    modifyPhoneDialogVisible.value = true
  }
  if (editType.value === 'api_key') {
    // if (currentUserInfo.value.api_token) {
    //   resetDialogVisible.value = true
    // } else {
    resetApikey()
    // }
  }
}

const handleReset = () => {
  if (currentUserInfo.value.phone || currentUserInfo.value.email) {
    if (currentUserInfo.value.api_token) {
      resetDialogVisible.value = true
    } else {
      edit('api_key')
    }
    // resetDialogVisible.value = true
  } else {
    message(currentUserInfo.value.api_token ? t('account_manager.edit_user.reset_apikey') : t('account_manager.edit_user.generate_apikey'), 'error')
  }
}

const handleResetApikey = () => {
  resetDialogVisible.value = false
  edit('api_key')
}

const resetApikey = () => {
  // 生成 apikey， 成功后重新回去用户信息
  resetApiKey().then(res => {
    // 提示
    resetDialogVisible.value = false
    if (res.code) {
      message(res.message, 'error')
    } else {
      message(t('action.handle'))
      getBaseData()
      if (route.query.referrer === 'app-auth') {
        router.back()
      }
    }
  })
}

const handleUpdate = () => {
  if (fileInput.value) {
    fileInput.value.click()
  }
}

const getImg = (aaa) => {
  console.log(fileInput.value.files[0].type)
  if (fileInput.value.files && fileInput.value.files[0]) {
    if (fileInput.value.files[0].size > 3 * 1024 * 1024) {
      message(t('account_manager.edit_user.avatar_size_limit'), 'error')
      return false
    } else {
      // formData.value.user_pic = fileInput.value.files[0]
      if (fileInput.value.files[0].type === 'image/png' || fileInput.value.files[0].type === 'image/gif' || fileInput.value.files[0].type === 'image/jpeg') {
        const windowURL = window.URL || window.webkitURL
        const dataURl = windowURL.createObjectURL(fileInput.value.files[0])
        // currentPic.value = dataURl
        defaultImg.value = dataURl
        imgVisible.value = true
      } else {
        message(t('account_manager.edit_user.avatar_type_limit'), 'error')
        return false
      }
    }
  }
}

const updateImg = (img) => {
  console.log(img, dataURLtoBlob(img))
  fileInput.value.value = ''
  imgVisible.value = false
  const formData = new FormData()
  formData.append('user_pic', blobtoFile(dataURLtoBlob(img)))
  editUserAvatar(formData).then(res => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      visible.value = false
      getBaseData()
      message(t('account_manager.edit_user.avatar_updated'))
    }
  })
}

function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {
    type: mime
  })
}

function blobtoFile(blob) {
  return new File([blob], 'logo.png', { type: blob.type })
}

const closeImgDialog = () => {
  imgVisible.value = false
}

// const closeDialog = () => {
//   visible.value = false
// }

// const updateInfo = (info) => {
//   const formData = new FormData()
//   Object.keys(info).map(item => {
//     if (item !== 'username') {
//       // console.log(item, info[item])
//       formData.append(item, info[item])
//     } else {
//       // console.log(info[item], userInfo.value.username)
//       if (info[item] !== userInfo.value.username) {
//         formData.append(item, info[item])
//       }
//     }
//   })
//   console.log(formData)
//   editUserAvatar(formData).then(res => {
//     if ('code' in res) {
//       message(res.message, 'error')
//     } else {
//       visible.value = false
//       getBaseData()
//       message('用户信息更新成功')
//     }
//   })
// }

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
[data-theme='light'] .base-info__wrapper {
  .changeAvatar {
    color: #242933 !important;
  }
}

[lang='en'] .base-info__wrapper .base-info__content .base-info__menu .base-info__item {
  .base-item__label  {
    width: 134px;
  }
  .base-item__value {
    width: calc(100% - 134px);
  }
}
.base-info__wrapper {
  box-sizing: border-box;
  .base-info__title {
    line-height: 50px;
    border-bottom: 1px solid var(--color-border-2);
    font-size: 16px;
    color: var(--color-text-1);
  }
  .base-info__content {
    overflow: hidden;
    // display: flex;
    box-sizing: border-box;
    padding: 40px 0;
    .base-info__logo {
      padding: 0 60px 0 30px;
      float: left;
      .logo-wrap {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        overflow: hidden;
        position: relative;
        &:hover {
          .action-wrap {
            display: block;
          }
          .changeAvatar {
            display: block;
          }
        }
        &>img {
          width: 120px;
        }
        .action-wrap {
          display: none;
          cursor: pointer;
          line-height: 120px;
          text-align: center;
          font-size: 20px;
          color: #515151;
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: var(--detect-config-mask)
        }
        .changeAvatar {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          cursor: pointer;
          transform: translate(-50%, -50%);
          font-size: 30px;
          display: none;
          color: #fff;
        }
      }
    }
    .base-info__menu {
      width: calc(100% - 210px);
      float: left;
      overflow: hidden;
      // display: flex;
      // flex-flow: row wrap;
      // align-content: flex-start;
      .base-info__item {
        // flex: 0 0 25%;
        float: left;
        width: 50%;
        line-height: 40px;
        // display: flex;
        overflow: hidden;
        &.base-info__item--line {
          width: 100%;
        }
        &.api-key__item {
          width: 100%;
        }
        &.api-key__info {
          width: 615px;
          font-size: 12px;
          color: var(--color-text-3);
          line-height: 18px;
        }
        .base-item__label {
          color: var(--color-text-3);
          width: 80px;
          float: left;
        }
        .base-item__value {
          // width: flex;
          float: left;
          color: var(--color-text-2);
          width: calc(100% - 80px);
          display: flex;
          align-items: center;
          &>span {
            max-width: calc(100% - 217px);
            float: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .bindMobilePhone {
            color: var(--color-priamry);
            cursor: pointer;
          }
          & > svg {
            color: var(--color-priamry);
            cursor: pointer;
            font-size: 16px;
          }
          .line {
            width: 1px;
            height: 14px;
            background: var(--color-border-2);
            margin-right: 10px;
            margin-left: 10px;
          }
          .api-key__action {
            color: var(--color-priamry);
            margin-left: 0;
            cursor: pointer;
            // &.reset-api__key {
            //   margin-right: 6px;
            // }
          }
          .api-key__wrapper {
            // display: inline-block;
            float: left;
            max-width: 600px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }
        }
      }
    }
  }
  .modifyEmailDialog {
    .code-item {
      width: 100%;
      display: flex;
      justify-content:space-between;
      :deep(.el-button) {
        height: 33px;
      }
    }
  }
}
</style>

<style lang="scss">
.deleteContent {
  display: flex;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
</style>
