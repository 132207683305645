<template>
  <div class="subscribe-list__wrapper">
    <div class="subscribe-list__title">
      {{ $t('account_manager.subscription.title') }}
    </div>
    <div class="subscribe-list__content">
      <div class="current-version">
        <div class="current-version__label">{{ $tLabel($t('account_manager.subscription.current_version')) }}</div>
        <div class="current-version__value">{{ userGroup }}</div>
      </div>
      <div class="subscribe-item__wrapper">
        <template v-for="(item, index) in list">
          <div class="subscribe-item" v-if="item.valueLabel" :key="index">
            <div class="subscribe-item__label">{{ item.label }}</div>
            <div class="subscribe-item__value">{{ item.valueLabel }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, getCurrentInstance } from 'vue'
import { useState } from '@/hooks/useVuex'
import { message } from '@/hooks/useUI'
import { getAccountSubscribe } from '@/services/account.js'
import { t } from 'app/i18n'

const { appContext: { config: { globalProperties } } } = getCurrentInstance()

const groupMap = {
  free: globalProperties.$t('UserGroup.free'),
  professional: globalProperties.$t('UserGroup.professional'),
  enterprise: globalProperties.$t('UserGroup.enterprise'),
  qihoo: globalProperties.$t('UserGroup.qihoo')
}

const userGroup = computed(() => {
  if (userInfo.value && userInfo.value.group) {
    return groupMap[userInfo.value.group]
  }
  return '-'
})
const { userInfo } = useState('user', ['userInfo'])

const isLoading = ref(false)
const list = ref([
  {
    label: globalProperties.$t('account_manager.subscription.max_file_size'),
    key: 'submit.max_file_size',
    type: 'quota',
    valueLabel: '',
    formatValue: (size) => {
      if (size) {
        return getSize(size)
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.max_time'),
    key: 'submit.max_time',
    type: 'quota',
    valueLabel: '',
    formatValue: (time) => {
      if (time) {
        return `${time}${t('account_manager.subscription.second')}`
      }
    }
  },
  // {
  //   label: '投递频率',
  //   key: 'submit.task_frequency_30d',
  //   type: 'quota',
  //   valueLabel: '',
  //   formatValue: (count) => {
  //     if (count) {
  //       return `${count} ${globalProperties.$t('account_manager.subscription.bout')}/月`
  //     }
  //   }
  // },
  {
    label: globalProperties.$t('account_manager.subscription.max_count_once'),
    key: 'submit.max_count_once',
    type: 'quota',
    valueLabel: '',
    formatValue: (count) => {
      if (count) {
        return `${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.task_frequency_1d'),
    key: 'submit.task_frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.task_frequency_1m'),
    key: 'submit.task_frequency_1m',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${count} ${globalProperties.$t('account_manager.subscription.bout')}`
      }
    }
  },
  // {
  //   label: '提交任务配额(每月)',
  //   key: 'submit.task_frequency_30d',
  //   type: 'quota_used',
  //   valueLabel: '',
  //   formatValue: (used, count) => {
  //     if (count) {
  //       return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
  //     }
  //   }
  // },
  {
    label: globalProperties.$t('account_manager.subscription.sample_frequency_1d'),
    key: 'download.sample.frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: t('account_manager.subscription.pdf_frequency_1d'),
    key: 'download.pdf.frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${t('account_manager.subscription.used')} ${used}/${count} ${t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.pcap_frequency_1d'),
    key: 'download.pcap.frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.dropped_frequency_1d'),
    key: 'download.dropped.frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.memdumps_frequency_1d'),
    key: 'download.memdumps.frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: t('account_manager.subscription.ai_frequency_1d'),
    key: 'chat.ai.frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${t('account_manager.subscription.used')} ${used}/${count} ${t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.view_task_frequency_1d'),
    key: 'view.task_frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.search_frequency_1m'),
    key: 'search.frequency_1m',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${count} ${globalProperties.$t('account_manager.subscription.bout')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.search_frequency_1d'),
    key: 'search.frequency_1d',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.used')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.bout')}`
      }
    }
  },
  {
    label: t('account_manager.subscription.ai_frequency_1m'),
    key: 'chat.ai.frequency_1m',
    type: 'quota',
    valueLabel: '',
    formatValue: (count) => {
      if (count) {
        return `${count} ${t('account_manager.subscription.bout')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.team_quota'),
    key: 'team_quota',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${globalProperties.$t('account_manager.subscription.create')} ${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: globalProperties.$t('account_manager.subscription.team_invate'),
    key: 'team_invate_quota',
    type: 'quota',
    valueLabel: '',
    formatValue: (count) => {
      if (count) {
        return `${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  // {
  //   label: '共享订阅权限',
  //   key: '',
  //   type: '',
  //   quota: 0,
  //   quota_used: 0
  // },
  {
    label: globalProperties.$t('account_manager.subscription.team_count'),
    key: 'team_count_quota',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${used}/${count} ${globalProperties.$t('account_manager.subscription.indivual')}`
      }
    }
  },
  {
    label: '创建静态狩猎规则',
    key: 'static_rule_quota',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${used}/${count} 个`
      }
    }
  },
  {
    label: '创建威胁狩猎规则',
    key: 'hunting_rule_quota',
    type: 'quota_used',
    valueLabel: '',
    formatValue: (used, count) => {
      if (count) {
        return `${used}/${count} 个`
      }
    }
  }
])

onMounted(() => {
  getData()
})

const getData = () => {
  isLoading.value = true
  getAccountSubscribe().then(res => {
    isLoading.value = false
    if (res.code) {
      message(res.message, 'error')
    } else {
      list.value.forEach(item => {
        console.log(item.key)
        if (item.type === 'quota') {
          item.valueLabel = !res.quota[item.key] ? '' : res.quota[item.key].quota === 999999999 ? t('account_manager.subscription.unlimited') : item.formatValue(res.quota[item.key].quota)
        } else {
          item.valueLabel = !res.quota[item.key] ? '' : res.quota[item.key].quota === 999999999 ? t('account_manager.subscription.unlimited') : item.formatValue(res.quota[item.key].quota_used, res.quota[item.key].quota)
        }
      })
    }
  })
}

function getSize(size) {
  if (size < 1024) {
    return `${size}B`
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(0)}K`
  }
  return `${(size / 1024 / 1024).toFixed(0)}M`
}

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
[data-theme=light]{
  .subscribe-item__wrapper {
    .subscribe-item {
      background-image: url('~@/assets/img/user-center/item-bg-light.png') !important;
    }
  }
}

.subscribe-list__wrapper {
  .subscribe-list__title {
    line-height: 50px;
    border-bottom: 1px solid var(--color-border-2);
    font-size: 16px;
    color: var(--color-text-1);
  }
  .subscribe-list__content {
    // overflow: hidden;
    padding: 20px 0 20px;
    .current-version {
      padding: 20px 0;
      display: flex;
      font-size: 14px;
      .current-version__label {
        color: var(--color-text-3);
        width: 80px;
      }
      .current-version__value {
        color: var(--color-text-2);
      }
    }
    .subscribe-item__wrapper {
      overflow: hidden;
      .subscribe-item {
        // height: 120px;
        float: left;
        box-sizing: border-box;
        background-image: url('~@/assets/img/user-center/item-bg.svg');
        background-size: auto 100%;
        background-position: top 0 right 0;
        background-repeat: no-repeat;
        background-color: var(--user-subscribe-card);
        width: calc((100% - 60px)/5);
        padding: 30px 10px 30px 30px;
        margin-bottom: 15px;
        border-radius: 2px;
        font-family: Helvetica;
        margin-left: 15px;
        // &:last-child:nth-child(5n - 1) {
        //   margin-right: calc(20% + 2px);
        // }
        &:nth-child(5n + 1) {
          margin-left: 0;
        }
        .subscribe-item__label {
          font-size: 14px;
          color: var(--color-text-2);
        }
        .subscribe-item__value {
          margin-top: 21px;
          font-size: 20px;
          color: var(--color-text-2);
        }
      }
    }
  }
}
</style>
