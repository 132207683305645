<template>
  <div class="wrapper">
    <el-button :loading="props.loading" class="btn-orange" :disabled="disabled" @click="emit('send')">{{valiBtn}}</el-button>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineExpose, defineProps, getCurrentInstance } from 'vue'

const { appContext: { config: { globalProperties } } } = getCurrentInstance()

const disabled = ref(false)
const valiBtn = ref(globalProperties.$t('account_manager.edit_user.get_captach'))
const emit = defineEmits(['send'])
const props = defineProps({
  loading: {
    default: false,
    type: Boolean
  }
})
defineExpose({ tackBtn })
function tackBtn() {
  let time = 60
  const timer = setInterval(() => {
    if (time === 0) {
      clearInterval(timer)
      valiBtn.value = globalProperties.$t('account_manager.edit_user.get_captach')
      disabled.value = false
    } else {
      disabled.value = true
      valiBtn.value = globalProperties.$t('account_manager.edit_user.captach_interval', [time])
      time--
    }
  }, 1000)
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding-left: 12px;
}
</style>
