<template>
  <el-dialog
    v-model="visible"
    :title="$t('account_manager.upload_user_pic.crop_pic')"
    :before-close="close"
    width="540px"
  >
    <div class="update-user__content">
      <div class="cropper-wrap">
        <VueCropper
          class="cropper"
          ref="cropper"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :fixed="option.fixed"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :minImgSize="option.minImgSize"
          :mode="option.mode"
          :img="currentPic || defaultImg"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixedNumber="[1, 1]"
          @realTime="realTime"
        ></VueCropper>
      </div>
      <div class="pre-wrap">
        <div class="real-img__wrap">
          <div :style="realImg.style">
            <img :src="realImg.url" :style="{ transform: realImg.transform }" alt="">
          </div>
        </div>
        <p>{{ $t('account_manager.upload_user_pic.pic_preview') }}</p>
      </div>
    </div>
    <div class="action-wrap">
      <span @click="changeFile">{{ $t('account_manager.upload_user_pic.reupload') }}</span>
      <div class="cropper-action__wrap">
        <span @click="scaleAction(-1)"><svg-icon name="jianqu"></svg-icon></span>
        <span @click="scaleAction(1)"><svg-icon name="tianjia1"></svg-icon></span>
      </div>
    </div>
    <input ref="fileInput" style="display: none;" type="file" :multiple="false" @change="getImg" accept="image/png,image/gif,image/jpeg" name="file" />
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleUpdate"
          >{{ $t('action.confirm') }}</el-button
        >
        <el-button @click="close">{{ $t('action.cancel') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import 'vue-cropper/dist/index.css'
import { VueCropper } from 'vue-cropper'
import { defineEmits, ref, defineProps, watch } from 'vue'
import { message } from '@/hooks/useUI'
import { t } from 'app/i18n'

const props = defineProps({
  updateVisible: {
    required: true,
    default: false
  },
  defaultImg: {
    required: false,
    default: () => {
      return {}
    }
  }
})

const currentPic = ref('')
const fileInput = ref()
const realImg = ref({
  url: '',
  style: {},
  transform: ''
})

watch(() => props.updateVisible, (val) => {
  if (val) {
    visible.value = true
  }
})

const emit = defineEmits(['updateImg', 'close'])
const cropper = ref()
const option = ref({
  outputSize: 0.1, // 裁剪生成图片的质量
  outputType: 'png', // 裁剪生成图片的格式
  info: false, // 裁剪框的大小信息
  canScale: true, // 图片是否允许滚轮缩放
  autoCrop: true, // 是否默认生成截图框
  fixed: true, // 是否开启截图框宽高固定比例
  full: false, // 是否输出原图比例的截图
  fixedBox: false, // 固定截图框大小 不允许改变
  canMove: false, // 上传图片是否可以移动
  canMoveBox: true, // 截图框能否拖动
  original: false, // 上传图片按照原始比例渲染
  centerBox: true, // 截图框是否被限制在图片里面
  autoCropWidth: 274,
  autoCropHeight: 274,
  // high:true,// 是否按照设备的dpr 输出等比例图片
  infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
  maxImgSize: 2000, // 限制图片最大宽度和高度
  minImgSize: 100,
  // enlarge: 1, //图片根据截图框输出比例倍数
  mode: 'contain' // 图片默认渲染方式
})
const visible = ref(false)

const close = () => {
  visible.value = false
  realImg.value = {
    url: '',
    style: {},
    transform: ''
  }
  currentPic.value = ''
  emit('close')
}

const realTime = (data) => {
  console.log(data)
  if (data.url) {
    realImg.value.url = data.url
    realImg.value.style = {
      width: data.w + 'px',
      height: data.h + 'px',
      overflow: 'hidden',
      margin: '0',
      zoom: 120 / data.w
    }
    realImg.value.transform = data.img.transform
  }
}

const scaleAction = (scale) => {
  cropper.value && cropper.value.changeScale(scale)
}

const handleUpdate = () => {
  cropper.value && cropper.value.getCropData(data => {
    // do something
    console.log(data)
    realImg.value = {
      url: '',
      style: {},
      transform: ''
    }
    currentPic.value = ''
    emit('updateImg', data)
    visible.value = false
  })
}

const getImg = (aaa) => {
  // console.log(fileInput.value.files)
  if (fileInput.value.files && fileInput.value.files[0]) {
    if (fileInput.value.files[0].size > 3 * 1024 * 1024) {
      message(t('account_manager.edit_user.avatar_size_limit'), 'error')
      return false
    } else {
      // formData.value.user_pic = fileInput.value.files[0]
      const windowURL = window.URL || window.webkitURL
      const dataURl = windowURL.createObjectURL(fileInput.value.files[0])
      currentPic.value = dataURl
    }
  }
}

const changeFile = () => {
  if (fileInput.value) {
    fileInput.value.click()
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.update-user__content {
  // height: 300px;
  overflow: hidden;
  .cropper-wrap {
    float: left;
    width: 300px;
    height: 300px;
    :deep(.cropper) {
      .cropper-modal {
        transform: scaleY(1.01)
      }
    }
  }
  .pre-wrap {
    float: left;
    width: 190px;
    height: 300px;
    .real-img__wrap {
      margin-left: 36px;
      height: 120px;
      width: 120px;
      border-radius: 60px;
      overflow: hidden;
      text-align: center;
      &>img {
        width: 100%;
        height: 100%;
      }
    }
    &>p {
      margin-top: 20px;
      font-size: 14px;
      color: var(--color-text-3);
      text-align: center;
    }
  }
}
.action-wrap {
  width: 300px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  &>span {
    cursor: pointer;
    &:hover {
      color: #00ab7a;
    }
  }
  .cropper-action__wrap {
    width: 60px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    &>span {
      cursor: pointer;
      &:hover {
        color: #00ab7a;
      }
    }
  }
}
</style>

<style lang="scss">
.update-user__content {
  // .cropper-face {
  //   border-radius: 86px;
  // }
  .cropper-view-box {
    // border-radius: 86px;
    outline-style: dashed;
    outline-color: var(--color-text-1);
  }
  .crop-point {
    background-color: var(--color-text-1);
    border-radius: 0;
  }
}
</style>
